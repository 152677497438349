.grid-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "leftside center rightside";
    height: 100vh;
}

.leftside {
    grid-area: leftside;
    background: #cfd8dc;
}
.rightside {
    grid-area: rightside;
    background: #cfd8dc;
}
.center {
    grid-area: center;
    display: grid;
    height: inherit;
    grid-gap: 10px;
}

.grid-main-content {
    display: grid;
    height: calc(100vh-70px);
    margin-top: 60px;
    overflow-y: auto;
    overflow-x: hidden;
}

.sticky {
    width: '100%';
    position: 'fixed';
    bottom: 0;
}

@media (max-width: 415px) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "center center center";    
    }
    .leftside, .rightside {
        display: grid;
    }
}