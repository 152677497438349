.grid {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    /* place-content: center center; */
    grid-gap: 25px;
}

.cell {
    display: grid;
    /* grid-template-columns: 1fr; */
    height: 100px;
    border: 1px solid #ccc;
    place-items: center;
}