body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "leftside center rightside";
    height: 100vh;
}

.leftside {
    grid-area: leftside;
    background: #cfd8dc;
}
.rightside {
    grid-area: rightside;
    background: #cfd8dc;
}
.center {
    grid-area: center;
    display: grid;
    height: inherit;
    grid-gap: 10px;
}

.grid-main-content {
    display: grid;
    height: calc(100vh-70px);
    margin-top: 60px;
    overflow-y: auto;
    overflow-x: hidden;
}

.sticky {
    width: '100%';
    position: 'fixed';
    bottom: 0;
}

@media (max-width: 415px) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "center center center";    
    }
    .leftside, .rightside {
        display: grid;
    }
}
.grid {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    /* place-content: center center; */
    grid-gap: 25px;
}

.cell {
    display: grid;
    /* grid-template-columns: 1fr; */
    height: 100px;
    border: 1px solid #ccc;
    place-items: center;
}
